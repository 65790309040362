import { message } from 'antd';
import { videoSourceService } from '../service/videoSourceService';

export type VideoSourceODKHandlersProps = {
  id: string;
  startFromBeginning: boolean;
  setLoading: (loading: boolean) => void;
};

export type VideoSourceODKHandlersReturn = {
  handleOnClickForceRefresh: () => void;
  handleOnClickBulkRefresh: () => void;
};

export const getVideoSourceODKHandlers = ({
  id,
  startFromBeginning,
  setLoading,
}: VideoSourceODKHandlersProps): VideoSourceODKHandlersReturn => {
  const handleOnClickForceRefresh = async (): Promise<void> => {
    setLoading(true);
    try {
      await videoSourceService.generate(Number(id));
      message.success('Force refresh success');
    } catch (error) {
      message.error(`Force refresh failed ${error}`);
    }
    setLoading(false);
  };

  const handleOnClickBulkRefresh = async (): Promise<void> => {
    setLoading(true);
    try {
      await videoSourceService.bulkOdkSync(startFromBeginning);
      message.success('Bulk refresh success');
    } catch (error) {
      message.error(`Bulk refresh failed ${error}`);
    }
    setLoading(false);
  };

  return {
    handleOnClickForceRefresh,
    handleOnClickBulkRefresh,
  };
};
