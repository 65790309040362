import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
interface ContentProviderTableHandlersProps {
  navigate: NavigateFunction;
}

interface ContentProviderTableHandlersReturn {
  onClickDeleteButton: (id: number) => Promise<void>;
  onClickEditButton: (id: number) => void;
  handleAddContentProviderClick: () => void;
}

export const getContentProviderTableHandlers = ({
  navigate,
}: ContentProviderTableHandlersProps): ContentProviderTableHandlersReturn => {
  const onClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const onClickEditButton = (id: number): void => {
    navigate(`/content-provider/edit/${id}`);
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddContentProviderClick = (): void => {
    navigate('/content-provider/add');
  };

  return {
    onClickDeleteButton,
    onClickEditButton,
    handleAddContentProviderClick,
  };
};
