import * as Component from 'components';
import { roleService } from 'permission/service/roleService';
import React, { useCallback, useEffect, useState } from 'react';
import { RolePermissionType } from '../../type/roleType';
import { getRoleColumns } from './RoleColumns';
import { RoleHandler } from './RoleHandler';

export default function Role(): React.JSX.Element {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const { handleAddRoleClick, handleEditRoleClick, handleRowExpand } = RoleHandler();

  const columns = getRoleColumns({ handleEditRoleClick });

  const fetchRoles = useCallback(
    async (
      page: number,
      pageSize: number,
      search?: string
    ): Promise<{ data: RolePermissionType[]; count: number }> => {
      const params = {
        page: page.toString(),
        page_size: pageSize.toString(),
        search: search || '',
      };
      const response = await roleService.fetch(params);
      return { data: response.results, count: response.count };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  return (
    <Component.BasePageComponent>
      <div className="role-container">
        <Component.TableComponent<RolePermissionType>
          key={refreshCounter}
          tableName="Role"
          searchTerm={searchTerm}
          dataFetch={fetchRoles}
          columns={columns}
          handleSearchTerm={setSearchTerm}
          handleAddClick={handleAddRoleClick}
          handleRowExpand={handleRowExpand}
        />
      </div>
    </Component.BasePageComponent>
  );
}
