import axios from 'axios';
import { ADMIN_USER_API_PATH, BASE_URL } from 'constants/api';
import { RoleType } from 'permission/type/roleType';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { UserInterface, UserUpdateInterface } from '../type/userType';
export class UserService {
  constructor(private readonly apiService: ApiService<UserInterface & UserUpdateInterface>) {}

  public async fetch(queryParams: Record<string, string>): Promise<FetchResult<UserInterface>> {
    return await this.apiService.fetch(queryParams);
  }

  public async update(id: number, user: UserUpdateInterface): Promise<UserInterface> {
    return await this.apiService.update(id, {
      ...user,
      company_id: user.company_id,
      platform_id: user.platform_id,
    });
  }

  public async delete(id: number): Promise<void> {
    await this.apiService.delete(id);
  }

  public async create(user: UserInterface): Promise<UserInterface> {
    return await this.apiService.create({
      ...user,
      company_id: user.company?.id,
      platform_id: user.platform.id,
    });
  }

  public async fetchById(id: number): Promise<UserInterface> {
    const response = await this.apiService.fetchById(id);
    return response;
  }

  public async updateRole(id: number, roleIds: number[]): Promise<void> {
    const response = await axios.patch(`${BASE_URL}${ADMIN_USER_API_PATH}${id}/roles/`, {
      role_ids: roleIds,
    });
    return response.data;
  }

  public async fetchRole(id: number): Promise<{ role: RoleType }[]> {
    const response = await axios.get(`${BASE_URL}${ADMIN_USER_API_PATH}${id}/roles/`);
    return response.data;
  }
}

export const userService = new UserService(new ApiService(ADMIN_USER_API_PATH));
