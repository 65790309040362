import { EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';
import { TableColumnInterface } from 'src/shared/components/table/Table';
import { RolePermissionType } from '../../type/roleType';
type RoleColumnProps = {
  handleEditRoleClick: (id: number) => void;
};

export const getRoleColumns = ({
  handleEditRoleClick,
}: RoleColumnProps): TableColumnInterface<RolePermissionType>[] => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_text: string, record: RolePermissionType) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          type="primary"
          className="action-btn edit-btn"
          onClick={() => {
            handleEditRoleClick(Number(record.id));
          }}
        />
      </Space>
    ),
  },
];
