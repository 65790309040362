import { Button, FormInstance, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { ImportVideoDataInterface } from '../../../type/videoSourceType';
import { getVideoSourcePreviewHandlers } from './VideoSourcePreviewHandlers';

interface VideoSourcePreviewProps {
  form: FormInstance;
}

const VideoSourcePreview = ({ form }: VideoSourcePreviewProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [importVideoDataList, setImportVideoDataList] = useState<ImportVideoDataInterface[]>([]);

  const { handleOnClickPreviewButton, handleCloseModal } = getVideoSourcePreviewHandlers({
    setLoading,
    setModalTitle,
    setModalVisible,
    setImportVideoDataList,
  });
  return (
    <div>
      <Button
        style={{ marginBottom: '20px' }}
        onClick={() =>
          handleOnClickPreviewButton(form.getFieldValue('mrss_url'), form.getFieldValue('name'))
        }
      >
        preview mrsss source
      </Button>
      <div>
        <Modal
          title={`Video List - ${modalTitle}`} // 동적으로 모달 제목 설정
          open={modalVisible}
          onCancel={handleCloseModal}
          footer={null} // 하단 버튼 제거
          width={800}
          confirmLoading={loading}
        >
          <Table
            dataSource={importVideoDataList}
            columns={[
              {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Publication Date',
                dataIndex: 'pubDate',
                key: 'pubDate',
              },
              {
                title: 'Thumbnail',
                dataIndex: 'thumbnailUrl',
                key: 'thumbnailUrl',
                render: (url: string): React.ReactElement => (
                  <img src={url} alt="Thumbnail" style={{ width: '50px', height: '50px' }} />
                ),
              },
              {
                title: 'Content URL',
                dataIndex: 'contentUrl',
                key: 'contentUrl',
                render: (url: string): React.ReactElement => (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                ),
              },
              {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
              },
            ]}
            rowKey="key"
            pagination={{ pageSize: 10 }}
            scroll={{ y: 400 }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default VideoSourcePreview;
