import { Button, Checkbox, Form, Input, Switch } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { videoSourceService } from '../service/videoSourceService';
import { VideoSourceInterface } from '../type/videoSourceType';
import { getVideoSourceODKHandlers } from './VideoSourceODKHandler';

const VideoSourceODK: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [videoSource, setVideoSource] = useState<VideoSourceInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startFromBeginning, setStartFromBeginning] = useState(false);
  const { handleOnClickForceRefresh, handleOnClickBulkRefresh } = getVideoSourceODKHandlers({
    id: id || '',
    startFromBeginning,
    setLoading,
  });

  useEffect(() => {
    const fetchVideoSource = async (): Promise<void> => {
      const fetchedVideoSource = await videoSourceService.getODKItem();
      if (fetchedVideoSource) {
        setVideoSource(fetchedVideoSource);
        form.setFieldsValue(fetchedVideoSource);
      }
    };

    fetchVideoSource().then(() => {
      setIsLoading(false);
    });
  }, [form]);

  return (
    <div>
      <Component.BasePageComponent>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <div className="video-source-details-page">
            <h1>Edit Video Source: {videoSource && videoSource.name}</h1>

            <>
              <div style={{ padding: '20px' }}>
                <Button
                  color="default"
                  variant="solid"
                  onClick={handleOnClickForceRefresh}
                  disabled={loading}
                >
                  Force Refresh
                </Button>
                <div>Last synced: {videoSource?.last_synced}</div>
              </div>
              <div style={{ padding: '20px', display: 'none' }}>
                <Button color="danger" variant="solid" onClick={handleOnClickBulkRefresh}>
                  Bulk Full Refresh
                </Button>
                <Checkbox
                  style={{ marginLeft: '20px' }}
                  onChange={e => setStartFromBeginning(e.target.checked)}
                >
                  start from beginning
                </Checkbox>
              </div>
            </>
            <Form
              form={form}
              layout="vertical"
              className="video-source-details-form"
              initialValues={videoSource}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter a name!' }]}
              >
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item
                label="MRSS URL"
                name="mrss_url"
                rules={[{ required: true, message: 'Please enter MRSS URL!' }]}
              >
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label="Auto Add Videos" name="auto_add_videos" valuePropName="checked">
                <Switch disabled={true} />
                {/* 실제 file을 s3에 올릴것인가 */}
              </Form.Item>
              <Form.Item
                label="Auto Activate Videos"
                name="auto_activate_videos"
                valuePropName="checked"
              >
                {/* 가져온 content active 상태 */}
                <Switch disabled={true} />
              </Form.Item>
            </Form>
          </div>
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceODK);
