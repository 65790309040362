import { ColumnGroupType, ColumnType, FilterValue, Key } from 'antd/es/table/interface';
import React from 'react';
import { RevenueInterface } from 'src/domain/revenue/type/revenueType';

export const useRevenueDashboardColumns = (
  filters: Record<string, FilterValue | null>
): (ColumnType<RevenueInterface> | ColumnGroupType<RevenueInterface>)[] => {
  return [
    {
      title: 'Year Month',
      dataIndex: 'yearmon',
      key: 'yearmon',
      onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
        record.yearmon === value,
      sorter: true,
    },
    filters.group_by?.[0] === 'yearmon_content_provider'
      ? {
          title: (): React.ReactNode => <span>Content Provider</span>,
          dataIndex: 'content_provider',
          key: 'content_provider',
          onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
            record.content_provider === value,
          sorter: true,
        }
      : undefined,
    filters.group_by?.[0] === 'yearmon_season'
      ? {
          title: (): React.ReactNode => <span>Season</span>,
          dataIndex: 'title_en',
          key: 'title_en',
          onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
            record.title_en === value,
          sorter: true,
        }
      : undefined,
    {
      title: 'VH',
      dataIndex: 'vh',
      key: 'vh',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Video Ad Impression',
      dataIndex: 'video_ad_imp',
      key: 'video_ad_imp',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Video Revenue(AVOD)',
      dataIndex: 'video_rev',
      key: 'video_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Display Revenue(AVOD)',
      dataIndex: 'display_rev',
      key: 'display_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Sponsorship Revenue',
      dataIndex: 'sponsorship_rev',
      key: 'sponsorship_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Distribution Revenue',
      dataIndex: 'distribution_rev',
      key: 'distribution_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Theatrical Revenue',
      dataIndex: 'theatrical_rev',
      key: 'theatrical_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'SVOD Revenue',
      dataIndex: 'svod_rev',
      key: 'svod_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'PPV Revenue',
      dataIndex: 'ppv_rev',
      key: 'ppv_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
    {
      title: 'Total Revenue',
      dataIndex: 'total_rev',
      key: 'total_rev',
      render: (value: number) => Number(value).toLocaleString('en-US'),
    },
  ].filter(Boolean) as (ColumnType<RevenueInterface> | ColumnGroupType<RevenueInterface>)[];
};
