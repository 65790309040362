import { Form } from 'antd';
import * as Components from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import { DetailComponent } from './components';

const AccountPage: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <Components.BasePageComponent>
      <DetailComponent form={form} />
    </Components.BasePageComponent>
  );
};

export default observer(AccountPage);
