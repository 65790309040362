import { userService } from 'account/services/userService';
import { FormInstance, message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { UserUpdateInterface } from '../../type/userType';

interface UserEditHandlersHook {
  id: number;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface UserEditHandlersReturn {
  handleOnClickEditButton: () => Promise<void>;
}

export const getUserEditHandlers = ({
  id,
  form,
  navigate,
}: UserEditHandlersHook): UserEditHandlersReturn => {
  const handleOnClickEditButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const values = await form.validateFields();
      const user: UserUpdateInterface = {
        id: id,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        platform_id: values.platform.id,
      };

      if (values.company?.id) {
        user.company_id = values.company.id;
      }

      await userService.update(id, user);
      await userService.updateRole(id, values.role_ids);
      loadingMessage();
      navigate('/user', {
        state: {
          message: '사용자 정보가 성공적으로 수정되었습니다',
          userId: id,
        },
      });
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  return {
    handleOnClickEditButton,
  };
};
