import { Button, Form, Input } from 'antd';
import * as Component from 'components';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getContentProviderEditHandlers } from './ContentProviderEditHandlers';

const ContentProviderEdit: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [, setCompany] = useState<CompanyInterface | null>(null);
  const { handleOnClickEditButton } = getContentProviderEditHandlers({
    navigate,
    form,
    id: id || '',
  });
  useEffect(() => {
    const fetchCompany = async (): Promise<void> => {
      const company = await companyService.fetchById(Number(id));
      setCompany(company);
      form.setFieldsValue(company);
    };
    fetchCompany().then();
  }, [id]);

  return (
    <Component.BasePageComponent>
      <Form form={form} onFinish={handleOnClickEditButton} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="title_en" label="Title_en">
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {ACTION_TITLE.EDIT}
          </Button>
        </Form.Item>
      </Form>
    </Component.BasePageComponent>
  );
};

export default observer(ContentProviderEdit);
