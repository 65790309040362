import { Avatar, Card } from 'antd';
import React from 'react';
import './Card.css';

const { Meta } = Card;

interface CustomCardProps {
  icon: React.ReactNode;
  description: string;
  buttons: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({ icon, description, buttons }) => {
  return (
    <Card hoverable className="custom-card" style={{ width: '100%', marginBottom: '16px' }}>
      <div className="card-content">
        <Meta
          avatar={<Avatar size={48} style={{ backgroundColor: '#f6f6f6' }} icon={icon} />}
          title={<span className="upload-description">{description}</span>}
        />
        <div className="buttons-container">{buttons}</div>
      </div>
    </Card>
  );
};

export default CustomCard;
