import { TablePaginationConfig } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type';

type RevenueTableHandlerProps = {
  form: FormInstance;
  filters: Record<string, FilterValue | null>;
  selectedRow: string | null;
  setSelectedRow: (row: string | null) => void;
  setTableData: (data: RevenueInterface[]) => void;
  setFilters: (filters: Record<string, FilterValue | null>) => void;
  setCurrent: (current: number) => void;
};

export const getRevenueTableHandler = ({
  form,
  filters,
  selectedRow,
  setSelectedRow,
  setFilters,
  setCurrent,
}: RevenueTableHandlerProps): {
  handleRowClick: (record: RevenueInterface) => void;
  handleTableChange: (
    pagination: TablePaginationConfig,
    prePagination: TablePaginationConfig,
    sorter: SorterResult<RevenueInterface> | SorterResult<RevenueInterface>[]
  ) => void;
} => {
  const handleRowClick = (record: RevenueInterface): void => {
    const rowKey = `${record.content_provider}:-${record.title_en}:-${record.yearmon}`;
    if (selectedRow === rowKey) {
      setSelectedRow(null);
    } else {
      setSelectedRow(rowKey);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    prePagination: TablePaginationConfig,
    sorter: SorterResult<RevenueInterface> | SorterResult<RevenueInterface>[]
  ): void => {
    if (pagination.current !== prePagination.current) {
      setCurrent(pagination.current || 1);
    } else {
      setCurrent(1);
    }
    const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter;
    const newFilters = {
      ...filters,
      order_by: field ? [String(field)] : null,
      order: order ? [order === 'ascend' ? 'asc' : 'desc'] : null,
    };
    setFilters(newFilters);
    form.setFieldValue('order_by', field ? [String(field)] : null);
    form.setFieldValue('order', order ? [order === 'ascend' ? 'asc' : 'desc'] : null);
  };

  return {
    handleRowClick,
    handleTableChange,
  };
};
