import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RolePermissionType } from '../../type/roleType';
import { RolePermission } from './components/RolePermission';

type RoleHandlers = {
  handleAddRoleClick: () => void;
  handleBulkDelete: () => Promise<void>;
  handleEditRoleClick: (id: number) => void;
  handleRowExpand: (record: RolePermissionType) => React.ReactNode;
};

export const RoleHandler = (): RoleHandlers => {
  const navigate = useNavigate();
  const handleAddRoleClick = (): void => {
    navigate('/role/add');
  };

  const handleBulkDelete = async (): Promise<void> => {
    console.log('handleBulkDelete');
  };

  const handleEditRoleClick = (id: number): void => {
    navigate(`/role/edit/${id}`);
  };

  const handleRowExpand = (record: RolePermissionType): React.ReactNode => {
    return <RolePermission permissions={record.permissions} />;
  };

  return {
    handleAddRoleClick,
    handleBulkDelete,
    handleEditRoleClick,
    handleRowExpand,
  };
};
