import { FilterValue } from 'antd/es/table/interface';
import * as Components from 'components';
import { COLORS } from 'constants/constant';
import { RevenueInterface } from 'content/type';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { revenueService } from 'src/domain/revenue/services/revenueService';
import { getRevenueBarChartHandler } from './RevenueBarChartHandler';
type RevenueBarChartProps = {
  filters: Record<string, FilterValue | null>;
  selectedMonth: string | null;
  selectedRow: string | null;
  setSelectedMonth: (month: string | null) => void;
};

const LegendList = [
  'video_rev',
  'display_rev',
  'sponsorship_rev',
  'distribution_rev',
  'theatrical_rev',
  'svod_rev',
  'ppv_rev',
];

export const RevenueBarChart: React.FC<RevenueBarChartProps> = ({
  selectedMonth,
  filters,
  selectedRow,
  setSelectedMonth,
}: RevenueBarChartProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [, setBarChartData] = useState<RevenueInterface[]>([]);
  const [filteredBarChartData, setFilteredBarChartData] = useState<RevenueInterface[]>([]);
  const [selectedLegend, setSelectedLegend] = useState<string | null>(null);
  const [hoverLegend, setHoverLegend] = useState<string | null>(null);
  const { handleLegendClick, handleBarClick } = getRevenueBarChartHandler({
    selectedMonth,
    selectedLegend,
    setSelectedMonth,
    setSelectedLegend,
  });

  useEffect(() => {
    setSelectedLegend(null);
    setHoverLegend(null);
  }, [filters]);

  const renderCustomLabel = ({ x, y, value, width }: LabelProps): React.ReactNode => {
    if (typeof x === 'undefined' || typeof y === 'undefined' || typeof value === 'undefined') {
      return null;
    }

    return (
      <Text
        x={Number(x) + Number(width) / 2} // x 위치를 막대의 중앙으로 조정
        y={Number(y) - 10} // y 위치를 막대 위로 약간 띄움
        fill="#007bff"
        textAnchor="middle"
        fontSize={14}
        fontWeight="bold"
      >
        {`${(Number(value) / 1000000).toFixed(1)}M`}
      </Text>
    );
  };

  const getOpacity = (currentKey: string): number => {
    if (hoverLegend) {
      return hoverLegend === currentKey ? 1 : 0.3;
    }

    if (selectedLegend) {
      return selectedLegend === currentKey ? 1 : 0.3;
    }
    return 1;
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      const [_, , yearmon] = selectedRow?.split(':-') ?? [];
      const queryParam: Record<string, string> = {
        season_ids: filters.title ? filters.title.join(',') : '',
        content_provider_ids: filters.content_provider ? filters.content_provider.join(',') : '',
        start_yearmon:
          yearmon === 'null' ? '' : String(yearmon ?? filters.start_yearmon?.[0] ?? ''),
        end_yearmon: yearmon === 'null' ? '' : String(yearmon ?? filters.end_yearmon?.[0] ?? ''),
        group_by: 'yearmon',
        order: 'asc',
      };
      Object.keys(queryParam).forEach(key => {
        if (!queryParam[key]) {
          delete queryParam[key];
        }
      });
      let startPage = 1;
      let hasNext = true;
      const { results, page_next: nextPage } = await revenueService.fetch(queryParam);
      const mergedData: RevenueInterface[] = results;
      hasNext = nextPage !== null;
      while (hasNext) {
        ++startPage;
        queryParam.page = startPage.toString();
        const { results, page_next: nextPage } = await revenueService.fetch(queryParam);
        hasNext = nextPage !== null;
        mergedData.push(...results);
      }

      setBarChartData(mergedData);
      setFilteredBarChartData(mergedData);
      setIsLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      {isLoading ? (
        <Components.LoadingSpinnerComponent />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={filteredBarChartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            onClick={e =>
              e && e.activePayload && handleBarClick(e.activePayload[0].payload.yearmon)
            }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="yearmon"
              tickFormatter={(value: string) => dayjs(value).format('YYYY-MM')}
            />
            <YAxis
              // scale="sqrt" // 제곱근 스케일 사용
              domain={['auto', 'auto']}
              tickFormatter={(value: number) => `${(value / 1000000).toFixed(1)}M`}
            />
            <Tooltip />
            <Legend
              onClick={handleLegendClick}
              wrapperStyle={{
                cursor: 'pointer',
              }}
              onMouseEnter={e => {
                setHoverLegend(e.dataKey as string);
              }}
              onMouseLeave={() => {
                setHoverLegend(null);
              }}
              formatter={(value, entry: Payload) => (
                <span
                  style={{
                    fontWeight: selectedMonth === entry.dataKey ? 'bold' : 'normal',
                    color: selectedMonth === entry.dataKey ? '#000' : '#666',
                  }}
                >
                  {value}
                </span>
              )}
            />
            {LegendList.map((legend, index) => (
              <Bar
                key={legend}
                dataKey={legend}
                stackId="a"
                fill={COLORS[index]}
                opacity={getOpacity(legend)}
              >
                {legend === LegendList[LegendList.length - 1] && (
                  <LabelList dataKey="total_rev" content={renderCustomLabel} />
                )}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
