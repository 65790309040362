import { FormInstance, message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { userService } from '../../services/userService';
import { UserInterface } from '../../type/userType';

interface UserAddHandlersHook {
  form: FormInstance;
  navigate: NavigateFunction;
}

interface UserAddHandlersReturn {
  handleOnClickAddButton: () => Promise<void>;
}

export const getUserAddHandlers = ({
  form,
  navigate,
}: UserAddHandlersHook): UserAddHandlersReturn => {
  const handleOnClickAddButton = async (): Promise<void> => {
    const platform = accountStore.getAccount()!.platform;
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const values = await form.validateFields();
      const newUser: UserInterface = {
        id: -1,
        username: values.username,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        platform: platform,
      };

      if (values.company) {
        newUser.company = values.company;
      }

      const createdUser = await userService.create(newUser);
      loadingMessage();
      navigate('/user', {
        state: {
          message: '사용자가 성공적으로 생성되었습니다',
          userId: createdUser.id,
        },
      });
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  return {
    handleOnClickAddButton,
  };
};
