import { FormInstance, Table } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { revenueService } from 'src/domain/revenue/services';
import { RevenueInterface } from 'src/domain/revenue/type/revenueType';
import './RevenueTable.css';
import { useRevenueDashboardColumns } from './revenueTableColumns';
import { getRevenueTableHandler } from './revenueTableHandler';

type RevenueTableProps = {
  form: FormInstance;
  filters: Record<string, FilterValue | null>;
  groupBy: string;
  selectedRow: string | null;
  selectedMonth: string | null;
  setSelectedRow: (row: string | null) => void;
  setFilters: (filters: Record<string, FilterValue | null>) => void;
};
export const RevenueTable: React.FC<RevenueTableProps> = ({
  form,
  filters,
  groupBy,
  selectedRow,
  selectedMonth,
  setSelectedRow,
  setFilters,
}: RevenueTableProps) => {
  const [tableData, setTableData] = useState<RevenueInterface[]>([]);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = useRevenueDashboardColumns(filters);

  const { handleTableChange } = getRevenueTableHandler({
    form,
    filters,
    selectedRow,
    setSelectedRow,
    setTableData,
    setFilters,
    setCurrent,
  });

  useEffect(() => {
    setIsLoading(true);
    const dataFetch = async (): Promise<void> => {
      const queryParam: Record<string, string> = {
        page: current.toString(),
        page_size: pageSize.toString(),
        season_ids: filters.title ? filters.title.join(',') : '',
        content_provider_ids: filters.content_provider ? filters.content_provider.join(',') : '',
        start_yearmon: selectedMonth ? selectedMonth : String(filters.start_yearmon?.[0] ?? ''),
        end_yearmon: selectedMonth ? selectedMonth : String(filters.end_yearmon?.[0] ?? ''),
        group_by: selectedRow ? 'yearmon' : String(groupBy),
        order_by: filters.order_by ? String(filters.order_by?.[0]) : 'yearmon',
        order: filters.order ? String(filters.order?.[0]) : 'desc',
      };

      Object.keys(queryParam).forEach(key => {
        if (queryParam[key] === '') {
          delete queryParam[key];
        }
      });

      const data = await revenueService.fetch(queryParam);
      setPageSize(data.page_size);
      setTotalSize(data.count);
      setSelectedRow(null);
      setTableData(data.results);
      setIsLoading(false);
    };
    dataFetch();
  }, [selectedMonth, filters, current, pageSize]);

  return (
    <Table<RevenueInterface>
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
      rowClassName={record =>
        `${record.content_provider}:-${record.title_en}:-${record.yearmon}` === selectedRow
          ? 'selected-row'
          : ''
      }
      rowKey={record => `${record.content_provider}:-${record.title_en}:-${record.yearmon}`}
      bordered
      pagination={{
        current,
        pageSize: pageSize,
        total: totalSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
      }}
      onChange={handleTableChange}
      // onRow={record => ({
      //   onClick: () => handleRowClick(record),
      // })}
    />
  );
};
