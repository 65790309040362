import { Form } from 'antd';
import { contentService } from 'content/service/contentService';
import { contractService } from 'contract/services/contractService';
import { ContractInterface } from 'contract/type';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import { platformService } from 'project/platform/service/platformService';
import { PlatformInterface } from 'project/platform/type/platformType';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailComponent as ContractDetailComponent } from 'src/domain/contract/page/contract/components';

const Contract: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const url = `${window.location.origin}/contract`;
  const [form] = Form.useForm();
  const [contract, setContract] = useState<ContractInterface | null>(null);
  const [companyList, setCompanyList] = useState<CompanyInterface[]>([]);
  const [platformList, setPlatformList] = useState<PlatformInterface[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const content = await contentService.fetchById(Number(id));
      if (content.contract) {
        const contract = await contractService.fetchById(content.contract);
        const companyList = await companyService.fetchCompany();
        const platformList = await platformService.fetchPlatform();
        setContract(contract);
        setCompanyList(companyList);
        setPlatformList(platformList);
      }
    };
    fetchData().then();
  }, [id]);

  return (
    <div>
      <Form form={form} layout="vertical" name="contentContract">
        <div>
          {contract ? (
            <>
              <a href={`${url}/edit/${contract.id}`}>{contract?.title}</a>
              <ContractDetailComponent
                readonly
                form={form}
                companyList={companyList}
                platformList={platformList}
              />
            </>
          ) : (
            <div>
              There is no selected contract. <br />
              <a href={`${url}`}>Move to Contract</a>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default memo(Contract);
