import {
  CloudUploadOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MoreOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { MenuProps, message } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import authService from '../shared/service/authService';

export const CP_MENU_KEY_LIST = [
  'import-upload',
  'content',
  'playlist',
  'metadata',
  'account',
  'logout',
];

export const META_DATA_STRUCTURE_MENU_ITEMS: MenuProps['items'] = [
  { key: 'category', icon: <UnorderedListOutlined />, label: <Link to="/category">Category</Link> },
  { key: 'people', icon: <UnorderedListOutlined />, label: <Link to="/people">People</Link> },
  { key: 'series', icon: <UnorderedListOutlined />, label: <Link to="/series">Series</Link> },
  { key: 'season', icon: <UnorderedListOutlined />, label: <Link to="/season">Season</Link> },
];

export const CONTRACT_MENU_ITEMS: MenuProps['items'] = [
  { key: 'contract', icon: <UnorderedListOutlined />, label: <Link to="/contract">Contract</Link> },
];

export const CONTENT_MENU_ITEMS: MenuProps['items'] = [
  {
    key: 'import-upload',
    icon: <CloudUploadOutlined />,
    label: <Link to="/import-upload">Import & Upload</Link>,
  },
  {
    key: 'content',
    icon: <UnorderedListOutlined />,
    label: <Link to="/library">Library</Link>,
  },
  {
    key: 'playlist',
    icon: <UnorderedListOutlined />,
    label: <Link to="/playlist">Playlists</Link>,
  },
  {
    key: 'metadata',
    icon: <MoreOutlined />,
    label: 'Metadata',
    children: META_DATA_STRUCTURE_MENU_ITEMS,
  },
];

export const ADMIN_MENU_ITEMS: MenuProps['items'] = [
  { key: 'account', icon: <IdcardOutlined />, label: <Link to="/account">My account</Link> },
  { key: 'user', icon: <UserOutlined />, label: <Link to="/user">User Management</Link> },
  {
    key: 'company',
    icon: <UsergroupAddOutlined />,
    label: <Link to="/content-provider">CP Management</Link>,
  },
  {
    key: 'role',
    icon: <UnorderedListOutlined />,
    label: <Link to="/role">Role</Link>,
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
    onClick: async (): Promise<void> => {
      await authService.logout();
      message.success('Logout success');
      window.location.reload();
    },
  },
];

export const REVENUE_MENU_ITEMS: MenuProps['items'] = [
  {
    key: 'revenue-share',
    icon: <UnorderedListOutlined />,
    label: <Link to="/revenue-share">Revenue Share</Link>,
  },
  {
    key: 'revenue-dashboard',
    icon: <UnorderedListOutlined />,
    label: <Link to="/revenue-dashboard">Revenue Dashboard</Link>,
  },
];

export const PERMISSION_MENU_ITEMS: MenuProps['items'] = [
  {
    key: 'role',
    icon: <UnorderedListOutlined />,
    label: <Link to="/role">Role</Link>,
  },
];
