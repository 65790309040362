import { COMPANY_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { CompanyInterface } from '../type/companyType';

export class CompanyService {
  constructor(private apiService: ApiService<CompanyInterface>) {}

  public async create(company: CompanyInterface): Promise<CompanyInterface> {
    return await this.apiService.create(company);
  }

  public async update(id: number, company: CompanyInterface): Promise<CompanyInterface> {
    return await this.apiService.update(id, company);
  }

  public async fetch(queryParams?: Record<string, string>): Promise<FetchResult<CompanyInterface>> {
    return await this.apiService.fetch(queryParams);
  }

  public async fetchById(id: number): Promise<CompanyInterface> {
    const response = await this.apiService.fetchById(id);
    return response;
  }

  public async fetchCompany(): Promise<CompanyInterface[]> {
    const queryParams = {
      page_size: '100',
    };
    const result = await this.apiService.fetch(queryParams);
    return result.results;
  }
}

export const companyService = new CompanyService(new ApiService(COMPANY_API_PATH));
