import * as Component from 'components';
import React from 'react';
import { RoleDetail } from './components/RoleDetail';

export default function RoleEdit(): React.JSX.Element {
  return (
    <Component.BasePageComponent>
      <RoleDetail />
    </Component.BasePageComponent>
  );
}
