import { Form } from 'antd';
import { observer } from 'mobx-react';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import { platformService } from 'project/platform/service/platformService';
import { PlatformInterface } from 'project/platform/type/platformType';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import * as ContractComponent from './components';
import { detailHandlers } from './handlers';

const ContractAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [companyList, setCompanyList] = useState<CompanyInterface[]>([]);
  const [platformList, setPlatformList] = useState<PlatformInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick } = detailHandlers({ id: '0', form, navigate });

  const fetchCompany = async (): Promise<void> => {
    const companyData = await companyService.fetchCompany();
    setCompanyList(companyData);
  };

  const fetchPlatform = async (): Promise<void> => {
    const platformData = await platformService.fetchPlatform();
    setPlatformList(platformData);
  };

  useEffect(() => {
    Promise.all([fetchCompany(), fetchPlatform()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <ContractComponent.DetailComponent
          form={form}
          companyList={companyList}
          platformList={platformList}
          actionTitle="ADD"
          handleOnClickButtonClick={handleOnClickAddButtonClick}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(ContractAdd);
