import { FormInstance, message } from 'antd';
import { categoryService } from 'content/service/categoryService';
import { NavigateFunction } from 'react-router-dom';

interface CategoryAddHandlersProps {
  form: FormInstance;
  navigate: NavigateFunction;
}

interface CategoryAddHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
}

export const getCategoryAddHandlers = ({
  form,
  navigate,
}: CategoryAddHandlersProps): CategoryAddHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      await categoryService.create(form);
      loadingMessage();
      navigate('/category');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  return {
    handleOnClickAddButtonClick,
  };
};
