import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoginHandlers } from './loginHandlers';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { onFinish } = getLoginHandlers({ navigate, setLoading });

  return (
    <div style={{ maxWidth: 300, margin: '100px auto' }}>
      <h1>Login</h1>
      <Form onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: '사용자 이름을 입력하세요' }]}
        >
          <Input placeholder="사용자 이름" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: '비밀번호를 입력하세요' }]}>
          <Input.Password placeholder="비밀번호" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            로그인
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
