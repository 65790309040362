import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import authService from 'service/authService';

interface LoginHandlersHook {
  navigate: NavigateFunction;
  setLoading: (loading: boolean) => void;
}

interface LoginHandlersReturn {
  onFinish: (values: { username: string; password: string }) => Promise<void>;
}

export const getLoginHandlers = ({
  navigate,
  setLoading,
}: LoginHandlersHook): LoginHandlersReturn => {
  const onFinish = async (values: { username: string; password: string }): Promise<void> => {
    setLoading(true);
    try {
      await authService.login(values.username, values.password);
      message.success('Login success');

      // 현재 위치가 '/main'이 아닐 경우에만 '/main'으로 이동
      if (window.location.pathname !== '/main') {
        navigate('/main');
      } else {
        // 현재 위치가 이미 '/main'인 경우, 페이지를 새로고침
        window.location.reload();
      }
    } catch (error) {
      message.error(`Login failed : ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return { onFinish };
};
