import { observer } from 'mobx-react';
import { companyService } from 'project/company/service/companyService';
import { platformService } from 'project/platform/service/platformService';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { userService } from '../../services/userService';
import { UserDetailType } from '../../type/userType';
import { getUserColumns } from './UserColumns';
import { getUserTableHandlers } from './UserHandlers';

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [contentProviderList, setContentProviderList] = useState<{ id: number; title: string }[]>(
    []
  );
  const { onClickEditButton, onClickDeleteButton, handleAddUserClick } = getUserTableHandlers({
    navigate,
  });

  const columns = getUserColumns({
    onClickEditButton,
    onClickDeleteButton,
    contentProviderList,
  });

  const fetchUser = async (
    page: number,
    pageSize: number
  ): Promise<{ data: UserDetailType[]; count: number }> => {
    const userList = await userService.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });

    const userListWithRole = await Promise.all(
      userList.results.map(async user => {
        const roles = await userService.fetchRole(user.id);
        return { ...user, roles: roles.map(role => role.role) };
      })
    );
    return { data: userListWithRole, count: userList.count };
  };

  useEffect(() => {
    const fetchCompany = async (): Promise<void> => {
      const companyList = await companyService.fetchCompany();
      const platformList = await platformService.fetchPlatform();
      setContentProviderList([...companyList, ...platformList]);
    };
    fetchCompany().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <Component.TableComponent<UserDetailType>
          tableName="User"
          columns={columns}
          dataFetch={fetchUser}
          handleAddClick={handleAddUserClick}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserPage);
