import { PERMISSION_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { PermissionType } from '../type/roleType';

class PermissionService {
  constructor(private apiService: ApiService<PermissionType>) {}

  async fetch(params: Record<string, string>): Promise<FetchResult<PermissionType>> {
    const response = await this.apiService.fetch(params);
    return response;
  }

  async fetchByIds(
    ids: number[],
    params?: Record<string, string>
  ): Promise<FetchResult<PermissionType>> {
    if (ids.length === 0) {
      return {
        results: [],
        count: 0,
        next: null,
        previous: null,
        page_next: null,
        page_previous: null,
        page_size: 0,
      };
    }
    const response = await this.apiService.fetchByIds(ids, params);
    return response;
  }

  async create(permission: PermissionType): Promise<PermissionType> {
    const response = await this.apiService.create(permission);
    return response;
  }

  async update(id: number, permission: PermissionType): Promise<PermissionType> {
    const response = await this.apiService.update(id, permission);
    return response;
  }

  async delete(id: number): Promise<void> {
    await this.apiService.delete(id);
  }
}

export const permissionService = new PermissionService(
  new ApiService<PermissionType>(PERMISSION_API_PATH)
);
