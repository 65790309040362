import { Button, Collapse, DatePicker, Form, FormInstance, Radio, Select, Space } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { seasonService } from 'content/service';
import { RevenueInterface, SeasonInterface } from 'content/type';
import { companyService } from 'project/company/service/companyService';
import { CompanyInterface } from 'project/company/type/companyType';
import React, { useCallback, useEffect, useState } from 'react';
import { getRevenueFilterHandler } from './revenueFilterHandler';

type RevenueFilterProps = {
  form: FormInstance;
  tableData: RevenueInterface[];
  groupBy: string;
  setFilters: (filters: Record<string, FilterValue | null>) => void;
  setGroupBy: (groupBy: string) => void;
};
export const RevenueFilter: React.FC<RevenueFilterProps> = ({
  form,
  setFilters,
  groupBy,
  setGroupBy,
}: RevenueFilterProps) => {
  const [companyList, setCompanyList] = useState<CompanyInterface[]>([]);
  const [seasonList, setSeasonList] = useState<SeasonInterface[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchContentProviderTerm, setSearchContentProviderTerm] = useState<string>('');
  const [isLoadingSeason, setIsLoadingSeason] = useState<boolean>(true);
  const [isLoadingContentProvider, setIsLoadingContentProvider] = useState<boolean>(true);
  const {
    handleFilterSubmit,
    handleReset,
    handleSeasonSearch,
    handleGroupBy,
    handleContentProviderSearch,
  } = getRevenueFilterHandler(
    form,
    setFilters,
    setSearchTerm,
    setGroupBy,
    setSearchContentProviderTerm
  );

  const fetchSeason = useCallback(
    async (page: number, pageSize: number): Promise<{ data: SeasonInterface[]; count: number }> => {
      const seasonList = await seasonService.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
      });
      setIsLoadingSeason(false);
      return { data: seasonList.results, count: seasonList.count };
    },
    [searchTerm]
  );

  const fetchCompany = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: CompanyInterface[]; count: number }> => {
      const companyList = await companyService.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchContentProviderTerm,
      });
      setIsLoadingContentProvider(false);
      return { data: companyList.results, count: companyList.count };
    },
    [searchContentProviderTerm]
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await companyService.fetchCompany();
      setCompanyList(response);
    };
    fetchData();
    form.resetFields();
    handleReset();
  }, []);
  useEffect(() => {
    setIsLoadingSeason(true);
    fetchSeason(1, 10).then(data => setSeasonList(data.data));
  }, [searchTerm]);
  useEffect(() => {
    setIsLoadingContentProvider(true);
    fetchCompany(1, 10).then(data => setCompanyList(data.data));
  }, [searchContentProviderTerm]);

  return (
    <Collapse
      defaultActiveKey={['1']}
      style={{ marginBottom: 20 }}
      items={[
        {
          key: '1',
          label: 'Revenue Filter',
          children: (
            <Form form={form} layout="horizontal" onFinish={handleFilterSubmit}>
              <div>
                <Form.Item name="start_yearmon" label="Start Date">
                  <DatePicker
                    picker="month"
                    style={{ width: '10%' }}
                    placeholder="Select start date"
                    format="YYYY-MM"
                  />
                </Form.Item>
                <Form.Item name="end_yearmon" label="End Date">
                  <DatePicker
                    picker="month"
                    style={{ width: '10%' }}
                    placeholder="Select end date"
                    format="YYYY-MM"
                  />
                </Form.Item>
              </div>
              <Form.Item name="content_provider" label="Content Provider" style={{ width: '20%' }}>
                <Select
                  allowClear
                  placeholder="Search provider"
                  mode="multiple"
                  optionFilterProp="children"
                  loading={isLoadingContentProvider}
                  onSearch={handleContentProviderSearch}
                  filterOption={false}
                  options={companyList.map(value => ({
                    label: value.title,
                    value: value.id.toString(),
                  }))}
                />
              </Form.Item>

              <Form.Item name="title" label="Title" style={{ width: '20%' }}>
                <Select
                  loading={isLoadingSeason}
                  allowClear
                  mode="multiple"
                  showSearch
                  placeholder="Search season"
                  optionFilterProp="children"
                  onSearch={handleSeasonSearch}
                  filterOption={false}
                  options={seasonList.map(season => ({
                    label: season.title,
                    value: season.id.toString(),
                  }))}
                />
              </Form.Item>

              <Radio.Group
                style={{ marginBottom: 20, fontSize: 16 }}
                onChange={e => handleGroupBy(e.target.value)}
                value={groupBy}
              >
                Group By:
                <Radio style={{ marginLeft: 10 }} value="yearmon">
                  Year Month
                </Radio>
                <Radio style={{ marginLeft: 10 }} value="yearmon_content_provider">
                  Year Month Content Provider
                </Radio>
                <Radio style={{ marginLeft: 10 }} value="yearmon_season">
                  Year Month Season
                </Radio>
              </Radio.Group>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Apply Filters
                  </Button>
                  <Button onClick={handleReset}>Reset</Button>
                </Space>
              </Form.Item>
            </Form>
          ),
        },
      ]}
    />
  );
};
