import { FormInstance } from 'antd';
import { videoSourceService } from '../service/videoSourceService';
import { VideoSourceInterface } from '../type/videoSourceType';

export type VideoSourceAddHandlersProps = {
  id: string;
  form: FormInstance;
  navigate: (path: string) => void;
};

export type VideoSourceAddHandlersReturn = {
  handleOnClickButton: () => void;
};

export const getVideoSourceAddHandlers = ({
  id,
  form,
  navigate,
}: VideoSourceAddHandlersProps): VideoSourceAddHandlersReturn => {
  const handleOnClickButton = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      const updatedVideoSource: VideoSourceInterface = {
        id: Number(id),
        created: values.created ? values.created.toISOString() : '',
        modified: values.modified ? values.modified.toISOString() : '',
        name: values.name,
        mrss_url: values.mrss_url,
        category: values.category || 'test',
        category_values: values.category_values,
        import_from: values.import_from,
        import_to: values.import_to,
        auto_add_videos: values.auto_add_videos || false,
        auto_activate_videos: values.auto_activate_videos || false,
        auto_update_videos: values.auto_update_videos || false,
        auto_update_custom_thumbnails: values.auto_update_custom_thumbnails || false,
        auto_update_video_data_sources: values.auto_update_video_data_sources || false,
        auto_deactivate_video_data_sources: values.auto_deactivate_video_data_sources || false,
        sync_interval: values.sync_interval,
        headers: values.headers,
        transcode_profile: values.transcode_profile,
        active: values.active,
        import_setting: values.import_setting,
        last_synced: values.last_synced,
      };

      await videoSourceService.create(updatedVideoSource);
      navigate('/video-source');
    } catch (error) {
      console.log('유효성 검사 실패:', error);
    }
  };

  return {
    handleOnClickButton,
  };
};
