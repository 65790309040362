import { Button, Flex, Form, Input, TableColumnsType, Typography } from 'antd';
import * as Component from 'components';
import { TransferDataSourceInterface } from 'components/transfer/Transfer';
import { roleService } from 'permission/service/roleService';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import roleDetailHandlers from './RoleDetailHandler';

export const RoleDetail = (): React.JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [, setRefreshKey] = useState(0);
  const [form] = Form.useForm();
  const { dataHandler, filterOption, handleSave } = roleDetailHandlers({
    id: id,
    form,
    setRefreshKey,
  });

  const fetchRoleDetail = async (): Promise<void> => {
    const role = await roleService.fetchById(Number(id));
    form.setFieldsValue(role);
  };

  useEffect(() => {
    if (id) {
      fetchRoleDetail();
    }
  }, [id]);

  const columns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'id',
      title: 'Id',
      width: '10%',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, record): React.ReactNode => {
        return (
          <Flex align="center" gap="small">
            <span>{record.name}</span>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Form form={form}>
        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Role Information
        </Typography.Title>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input name' }]}
        >
          <Input style={{ width: '15%' }} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input style={{ width: '15%' }} />
        </Form.Item>

        <Typography.Title level={4} style={{ marginTop: '16px', marginBottom: '20px' }}>
          Permission Management
        </Typography.Title>
        <Component.TransferComponent
          dataHandler={dataHandler}
          filterOption={filterOption}
          columns={columns}
        />
        <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={() => {
              handleSave();
              navigate('/role/');
            }}
          >
            save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
