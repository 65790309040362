export const UPLOAD_CHUNK_SIZE = 100 * 1024 * 1024; // 100MB

export const CONTENT_TYPE = ['movie', 'tv_show', 'shortform'];
export const AGE_RATING = ['ALL', 'PG', 'PG-13', 'R', 'NC-17'];
export const STATUS = [
  'draft',
  'pending',
  'published',
  'archived',
  'suspended',
  'deleting',
  'scheduled',
  'expired',
];

export const LANGUAGE_LIST = ['ko', 'es', 'en', 'cn', 'jp'];
export const SUBTITLE_FILE_EXTENSION = ['.srt', '.vtt', '.smi'];
export const DUBBING_FILE_EXTENSION = ['.wav', '.aac', '.mp3'];

export const METADATA_SUB_PATHS = ['category', 'people', 'series', 'season'];

export const THUMBNAIL_UPLOAD_STATE = {
  UPLOADED: 'Uploaded',
  UPLOADING: 'Uploading',
};

export const CONTENT_RULE_TYPE = ['content', 'season', 'series'];

export const COLORS = [
  '#FF3B3B', // 선명한 빨강
  '#00C49F', // 에메랄드 그린
  '#FF66CC', // 밝은 파랑
  '#FFBB28', // 골든 옐로우
  '#9C27B0', // 보라
  '#FF6B3D', // 오렌지
  '#1976D2', // 깊은 파랑
  '#2E7D32', // 깊은 녹색
  '#FFC107', // 노랑
  '#FF9800', // 오렌지
  '#606060', // 회색
];

export const ACTION_TITLE = {
  ADD: 'Add',
  EDIT: 'Edit',
};
