import { DatePicker, Form, Input, Switch } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { videoSourceService } from '../service/videoSourceService';
import { VideoSourceInterface } from '../type/videoSourceType';
import { getVideoSourceAddHandlers } from './VideoSourceAddHandler';
import VideoSourcePreview from './components/preview/VideoSourcePreview';

const VideoSourceAdd: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [videoSource, setVideoSource] = useState<VideoSourceInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { handleOnClickButton } = getVideoSourceAddHandlers({
    id: id || '',
    form,
    navigate,
  });

  useEffect(() => {
    const fetchVideoSource = async (): Promise<void> => {
      const fetchedVideoSource = await videoSourceService.fetchById(Number(id));
      if (fetchedVideoSource) {
        setVideoSource(fetchedVideoSource);
        form.setFieldsValue(fetchedVideoSource);
      }
    };

    fetchVideoSource().then(() => {
      setIsLoading(false);
    });
  }, [id, form]);

  return (
    <div>
      <Component.BasePageComponent>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <div className="video-source-details-page">
            <h1>Edit Video Source: {videoSource && videoSource.name}</h1>
            <Form
              form={form}
              layout="vertical"
              className="video-source-details-form"
              initialValues={videoSource}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter a name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="MRSS URL"
                name="mrss_url"
                rules={[{ required: true, message: 'Please enter MRSS URL!' }]}
              >
                <Input />
              </Form.Item>
              <VideoSourcePreview form={form} />
              <Form.Item label="Auto Add Videos" name="auto_add_videos" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                label="Auto Activate Videos"
                name="auto_activate_videos"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item label="Category" name="category">
                <Input />
              </Form.Item>
              <Form.Item label="Category Values" name="category_values">
                <Input />
              </Form.Item>
              <Form.Item label="Import Start Date" name="import_from">
                <DatePicker />
              </Form.Item>
              <Form.Item label="Import End Date" name="import_to">
                <DatePicker />
              </Form.Item>
              <Component.ColorButtonComponent
                text={ACTION_TITLE.ADD}
                onClick={handleOnClickButton}
              />
            </Form>
          </div>
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceAdd);
