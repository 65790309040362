import { FilterValue } from 'antd/es/table/interface';
import * as Components from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { revenueService } from 'src/domain/revenue/services';
import { RevenueInterface } from 'src/domain/revenue/type/revenueType';
type RevenuePieChartProps = {
  filters: Record<string, FilterValue | null>;
  selectedRow: string | null;
};

export const RevenuePieChart: React.FC<RevenuePieChartProps> = ({
  filters,
  selectedRow,
}: RevenuePieChartProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const filteredPieDataRef = useRef<{ name: string; value: number }[]>([]);
  const [pieData, setPieData] = useState<{ name: string; value: number }[]>([]);
  const hiddenSeriesRef = useRef(new Set<string>());
  const [, forceRender] = useState(0);
  const [filteredPieChartData, setFilteredPieChartData] = useState<RevenueInterface[]>([]);

  const COLORS = [
    '#FF3B3B', // 선명한 빨강
    '#00C49F', // 에메랄드 그린
    '#0088FE', // 밝은 파랑
    '#FFBB28', // 골든 옐로우
    '#9C27B0', // 보라
    '#FF6B3D', // 오렌지
    '#1976D2', // 깊은 파랑
    '#2E7D32', // 깊은 녹색
    '#FFC107', // 노랑
    '#FF9800', // 오렌지
    '#FF5722', // 빨강
    '#606060', // 회색
  ];

  const transformData = (data: RevenueInterface[]): { name: string; value: number }[] => {
    if (!data.length) return [];

    const item = data[0];
    return [
      { name: 'Video Ad', value: parseFloat(item.video_ad_imp) },
      { name: 'Video Revenue', value: parseFloat(item.video_rev) },
      { name: 'Display Revenue', value: parseFloat(item.display_rev) },
      { name: 'Sponsorship Revenue', value: parseFloat(item.sponsorship_rev) },
      { name: 'Distribution Revenue', value: parseFloat(item.distribution_rev) },
      { name: 'VH', value: parseFloat(item.vh) },
      { name: 'VP', value: parseFloat(item.vp) },
      { name: 'AVOD', value: parseFloat(item.avod_rev) },
      { name: 'SVOD', value: parseFloat(item.svod_rev) },
      { name: 'PPV', value: parseFloat(item.ppv_rev) },
      { name: 'Theatrical', value: parseFloat(item.theatrical_rev) },
    ].filter(item => item.value > 0); // 값이 0인 항목 제외
  };

  const CustomTooltip = React.useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>): React.ReactNode => {
      if (active && payload && payload.length) {
        const total = pieData.reduce((sum, item) => sum + item.value, 0);
        const percent = (((payload[0].value as number) / total) * 100).toFixed(1);
        return (
          <div style={{ backgroundColor: 'white', padding: '5px 10px', border: '1px solid #ccc' }}>
            <p>{`${payload[0].name}: ${Number(payload[0].value).toLocaleString()} (${percent}%)`}</p>
          </div>
        );
      }
      return null;
    },
    [pieData]
  );

  const legendFormatter = useCallback(
    (value: string) => {
      const index = pieData.findIndex(item => item.name === value);
      const color = COLORS[index % COLORS.length];
      return (
        <span style={{ color: hiddenSeriesRef.current.has(value) ? `${color}66` : color }}>
          {value}
        </span>
      );
    },
    [hiddenSeriesRef]
  );

  const renderCustomizedLabel = React.useCallback(
    ({
      cx,
      cy,
      midAngle,
      outerRadius,
      percent,
      name,
    }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      name: string;
    }): React.ReactNode => {
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + 30;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
        <text
          x={x}
          y={y}
          fill="#000"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {!hiddenSeriesRef.current.has(name) ? `${name} (${(percent * 100).toFixed(1)}%)` : ''}
        </text>
      );
    },
    []
  );

  const handleLegendClick = useCallback((entry: { value: string }): void => {
    const updatedHiddenSeries = new Set(hiddenSeriesRef.current);
    if (updatedHiddenSeries.has(entry.value)) {
      updatedHiddenSeries.delete(entry.value);
    } else {
      updatedHiddenSeries.add(entry.value);
    }

    if (
      JSON.stringify(Array.from(hiddenSeriesRef.current)) !==
      JSON.stringify(Array.from(updatedHiddenSeries))
    ) {
      hiddenSeriesRef.current = updatedHiddenSeries;
      forceRender(prev => prev + 1); // 변경된 경우에만 리렌더링
    }
  }, []);

  useEffect(() => {
    const transformed = transformData(filteredPieChartData);
    if (JSON.stringify(filteredPieDataRef.current) !== JSON.stringify(transformed)) {
      filteredPieDataRef.current = transformed;
      setPieData(transformed); // 상태 업데이트 최소화
    }
  }, [filteredPieChartData]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const groupBy = filters.group_by?.[0] === 'content_provider' ? 'content_provider' : 'yearmon';
      const [_, rowTitle, yearmon] = selectedRow?.split(':-') ?? [];
      const queryParam: Record<string, string> = {
        content_id: String(filters.content_id?.[0] ?? ''),
        title: rowTitle === 'null' ? '' : String(rowTitle ?? filters.title?.[0] ?? ''),
        start_yearmon:
          yearmon === 'null' ? '' : String(yearmon ?? filters.start_yearmon?.[0] ?? ''),
        end_yearmon: yearmon === 'null' ? '' : String(yearmon ?? filters.end_yearmon?.[0] ?? ''),
        content_provider_ids: filters.content_provider ? filters.content_provider.join(',') : '',
        group_by: selectedRow ? 'yearmon' : String(groupBy),
        order_by: String(filters.order_by?.[0] ?? ''),
        order: String(filters.order?.[0] ?? ''),
        paging: String(filters.paging?.[0] ?? ''),
      };
      Object.keys(queryParam).forEach(key => {
        if (!queryParam[key]) {
          delete queryParam[key];
        }
      });
      const data = (await revenueService.fetch(queryParam)).results;
      setFilteredPieChartData(data);
      setIsLoading(false);
    };
    fetchData();
  }, [filters, selectedRow]);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {isLoading ? (
        <Components.LoadingSpinnerComponent />
      ) : (
        <PieChart width={1000} height={520}>
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={200}
            fill="#8884d8"
            isAnimationActive={false}
            label={renderCustomizedLabel}
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={COLORS[index % COLORS.length]}
                opacity={hiddenSeriesRef.current.has(entry.name) ? 0.2 : 1}
              />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconType="circle"
            onClick={handleLegendClick}
            formatter={legendFormatter}
          />
        </PieChart>
      )}
    </div>
  );
};
