import { Form, Input } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { categoryService } from '../../service/categoryService';
import { CategoryInterface } from '../../type/categoryType';
import { getCategoryEditHandlers } from './CategoryEditHandlers';

const CategoryEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleOnClickEditButtonClick } = getCategoryEditHandlers({
    id: id || '0',
    form,
    navigate,
  });

  useEffect(() => {
    categoryService.fetchById(Number(id)).then((category: CategoryInterface) => {
      form.setFieldsValue(category);
      setIsLoading(false);
    });
  }, [id]);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <div>
          <div className="people-details-page">
            <h1>Category</h1>
            <Form form={form} layout="vertical" className="people-details-form">
              <Form.Item
                name="name"
                label={
                  <>
                    <div style={{ marginRight: '10px' }}>Name</div>
                    <span style={{ fontSize: '10px', color: 'gray' }}>
                      * All category name saves to lowercase.
                    </span>
                  </>
                }
                rules={[{ required: true, message: 'please enter name' }]}
              >
                <Input onChange={e => (e.target.value = e.target.value.toLowerCase())} />
              </Form.Item>
              <Form.Item style={{ marginTop: '10px' }} name="description" label="description">
                <Input />
              </Form.Item>
            </Form>
          </div>
          <div className="people-detail-update-button">
            <Component.ColorButtonComponent
              text={ACTION_TITLE.EDIT}
              onClick={handleOnClickEditButtonClick}
            />
          </div>
        </div>
      )}
    </Component.BasePageComponent>
  );
};

export default observer(CategoryEdit);
