import { FormInstance } from 'antd/es/form';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
export const getRevenueFilterHandler = (
  form: FormInstance,
  setFilters: (filters: Record<string, FilterValue | null>) => void,
  setSearchTerm: (searchTerm: string) => void,
  setGroupBy: (groupBy: string) => void,
  setSearchContentProviderTerm: (searchContentProviderTerm: string) => void
): {
  handleFilterSubmit: (values: Record<string, FilterValue | null>) => void;
  handleReset: () => void;
  handleSeasonSearch: (searchTerm: string) => void;
  handleGroupBy: (groupBy: string) => void;
  handleContentProviderSearch: (searchContentProviderTerm: string) => void;
} => {
  const handleFilterSubmit = (values: Record<string, FilterValue | null>): void => {
    const newFilters: Record<string, FilterValue | null> = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value !== null) {
        if (key === 'start_yearmon' || key === 'end_yearmon') {
          const dateValue = value as unknown as dayjs.Dayjs;
          newFilters[key] = [dateValue.format('YYYY-MM')];
        } else {
          newFilters[key] = Array.isArray(value) ? value : [value];
        }
      }
    });

    setFilters(newFilters); // 테이블 필터 상태 업데이트
  };

  // 필터 초기화 함수
  const handleReset = (): void => {
    setFilters({
      start_yearmon: ['2022-01'],
      end_yearmon: ['2025-01'],
      title: [],
      group_by: ['yearmon'],
    });
    setGroupBy('yearmon');
    form.resetFields();
    form.setFieldsValue({
      start_yearmon: dayjs('2022-01'),
      end_yearmon: dayjs('2025-01'),
      title: [],
      group_by: ['yearmon'],
    });
  };

  const handleSeasonSearch = (searchTerm: string): void => {
    setSearchTerm(searchTerm);
  };

  const handleGroupBy = (groupBy: string): void => {
    setGroupBy(groupBy);
  };

  const handleContentProviderSearch = (searchContentProviderTerm: string): void => {
    setSearchContentProviderTerm(searchContentProviderTerm);
  };

  return {
    handleFilterSubmit,
    handleReset,
    handleSeasonSearch,
    handleGroupBy,
    handleContentProviderSearch,
  };
};
