import { Form } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import * as Components from 'components';
import { RevenueInterface } from 'content/type';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { RevenueBarChart } from './components/RevenueBarChart/RevenueBarChart';
import { RevenueFilter } from './components/RevenueFilter/revenueFilter';
import { RevenuePieChart } from './components/RevenuePieChart/RevenuePieChart';
import { RevenueTable } from './components/RevenueTable/RevenueTable';

export const RevenueDashboard: React.FC = () => {
  const [form] = Form.useForm();
  const [tableData] = useState<RevenueInterface[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({
    group_by: ['year_month'],
    start_yearmon: ['2022-01'],
    end_yearmon: ['2025-01'],
  });
  const [groupBy, setGroupBy] = useState<string>('yearmon');

  useEffect(() => {
    if (!filters.group_by) {
      filters.group_by = [groupBy];
    }

    if (!filters.content_provider) {
      filters.content_provider = [];
    }

    form.setFieldsValue(
      Object.entries(filters).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            ['start_yearmon', 'end_yearmon'].includes(key) && Array.isArray(value)
              ? dayjs(value[0].toString())
              : value || undefined,
        }),
        {}
      )
    );
  }, [filters]);

  useEffect(() => {}, [selectedMonth]);

  return (
    <Components.BasePageComponent>
      <div style={{ padding: 20 }}>
        <h2>Revenue Analysis</h2>
        <RevenueFilter
          form={form}
          groupBy={groupBy}
          tableData={tableData}
          setFilters={setFilters}
          setGroupBy={setGroupBy}
        />
        {selectedRow &&
        (filters.group_by?.[0] === 'yearmon' || filters.group_by?.[0] === 'content_yearmon') ? (
          <RevenuePieChart filters={filters} selectedRow={selectedRow} />
        ) : (
          <RevenueBarChart
            filters={filters}
            selectedMonth={selectedMonth}
            selectedRow={selectedRow}
            setSelectedMonth={setSelectedMonth}
          />
        )}
        <RevenueTable
          form={form}
          filters={filters}
          groupBy={groupBy}
          selectedMonth={selectedMonth}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setFilters={setFilters}
        />
      </div>
    </Components.BasePageComponent>
  );
};

export default RevenueDashboard;
