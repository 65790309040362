import { Form, Input } from 'antd';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { getCategoryAddHandlers } from './CategoryAddHandlers';

const CategoryAdd: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleOnClickAddButtonClick } = getCategoryAddHandlers({ form, navigate });

  return (
    <Component.BasePageComponent>
      <div>
        <div className="people-details-page">
          <h1>Category</h1>
          <Form form={form} layout="vertical" className="people-details-form">
            <Form.Item
              name="name"
              label={
                <>
                  <div style={{ marginRight: '10px' }}>Name</div>
                  <span style={{ fontSize: '10px', color: 'gray' }}>
                    * All category name saves to lowercase.
                  </span>
                </>
              }
              rules={[{ required: true, message: 'please enter name' }]}
            >
              <Input onChange={e => (e.target.value = e.target.value.toLowerCase())} />
            </Form.Item>
            <Form.Item style={{ marginTop: '10px' }} name="description" label="description">
              <Input />
            </Form.Item>
          </Form>
        </div>
        <div className="people-detail-update-button">
          <Component.ColorButtonComponent
            text={ACTION_TITLE.ADD}
            onClick={handleOnClickAddButtonClick}
          />
        </div>
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(CategoryAdd);
