import { Button, Form, Input } from 'antd';
import * as Component from 'components';
import { ACTION_TITLE } from 'constants/constant';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getContentProviderAddHandlers } from './ContentProviderAddHandlers';

const ContentProviderAdd: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { handleOnClickAddButton } = getContentProviderAddHandlers({ navigate, form });
  return (
    <Component.BasePageComponent>
      <Form form={form} onFinish={handleOnClickAddButton} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="title_en" label="Title_en">
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {ACTION_TITLE.ADD}
          </Button>
        </Form.Item>
      </Form>
    </Component.BasePageComponent>
  );
};

export default observer(ContentProviderAdd);
