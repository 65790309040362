import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';

interface CategoryHandlers {
  handleOnClickDeleteButton: (id: number) => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddCategoryClick: () => void;
}

interface CategoryHandlersProps {
  navigate: NavigateFunction;
}

export const getCategoryHandlers = ({ navigate }: CategoryHandlersProps): CategoryHandlers => {
  const handleOnClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/category/edit/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/category/edit/${id}`);
  };

  const handleAddCategoryClick = (): void => {
    navigate('/category/add');
  };

  return {
    handleOnClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddCategoryClick,
  };
};
