import axios from 'axios';
import { BASE_URL, PLATFORM_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { PlatformInterface } from '../type/platformType';

export class PlatformService {
  constructor(private apiService: ApiService<PlatformInterface>) {}
  public async fetchPlatform(): Promise<PlatformInterface[]> {
    const queryParams = {
      page_size: '100',
    };
    const result = await this.apiService.fetch(queryParams);
    return result.results;
  }

  public async fetchById(id: number): Promise<PlatformInterface> {
    const response = await axios.get(`${BASE_URL}${PLATFORM_API_PATH}${id}/`);
    return response.data;
  }
}

export const platformService = new PlatformService(new ApiService(PLATFORM_API_PATH));
